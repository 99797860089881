import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import styled from 'styled-components'

const IntroText = styled.div`
  text-align: center
`

export default function About({location}) {

  const {site:{siteMetadata:{title:siteTitle}}} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata{
            title
          }
        }
      }
    `
  )

  return (
    <Layout location={location}>
      <div style={{ background: '#fff' }}>
        <Helmet title={siteTitle} />

        <IntroText>
          TBD
          <br/>


        </IntroText>
      </div>
    </Layout>
  )
}
